import { useDevice } from '@/Lib/hooks/useDevice';

const DownloadIcon = () => {
  const { isMD } = useDevice();
  return (
    <svg
      width={isMD ? '24' : '16'}
      height={isMD ? '24' : '16'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6145 16.8018C11.4133 16.8022 11.2203 16.7217 11.0777 16.5783L8.74378 14.2101C8.6712 14.139 8.61337 14.0541 8.57362 13.9601C8.53386 13.8663 8.513 13.7652 8.51227 13.6631C8.51154 13.561 8.53094 13.4597 8.56934 13.3652C8.60774 13.2708 8.66436 13.1849 8.73592 13.1128C8.80747 13.0407 8.89252 12.9837 8.9861 12.9452C9.07968 12.9067 9.17993 12.8873 9.28098 12.8883C9.38202 12.8894 9.48184 12.9108 9.57464 12.9512C9.66743 12.9916 9.75135 13.0504 9.82147 13.1239L10.8565 14.1742V9.95672C10.8565 9.75272 10.9367 9.55706 11.0793 9.4128C11.2221 9.26854 11.4157 9.1875 11.6175 9.1875C11.8194 9.1875 12.013 9.26854 12.1557 9.4128C12.2984 9.55706 12.3786 9.75272 12.3786 9.95672V14.168L13.4116 13.1198C13.4817 13.0463 13.5656 12.9875 13.6584 12.9471C13.7511 12.9067 13.851 12.8853 13.9521 12.8843C14.0531 12.8832 14.1534 12.9026 14.2469 12.9411C14.3405 12.9796 14.4255 13.0366 14.4971 13.1087C14.5687 13.1808 14.6253 13.2667 14.6637 13.3611C14.702 13.4556 14.7215 13.5569 14.7207 13.659C14.72 13.7611 14.6992 13.8622 14.6594 13.956C14.6197 14.0499 14.5618 14.1349 14.4893 14.2059L12.1888 16.5413C12.1171 16.6237 12.0287 16.6896 11.9296 16.7346C11.8305 16.7795 11.7231 16.8025 11.6145 16.8018Z"
        fill="black"
      />
      <path
        d="M16.0593 22H7.98605C6.77239 21.9752 5.61815 21.4643 4.77708 20.5796C3.93603 19.6949 3.47697 18.5087 3.50089 17.2821V6.52C3.53144 5.31553 4.02442 4.17033 4.87553 3.32673C5.72665 2.48314 6.86911 2.00733 8.06114 2H13.8126C14.1955 2.00064 14.5742 2.07916 14.9264 2.23087C15.2785 2.38258 15.5968 2.60439 15.8624 2.88308L19.7083 6.93333C20.2178 7.4675 20.5017 8.1811 20.4998 8.92308V17.2954C20.5122 18.5098 20.055 19.6812 19.2256 20.56C18.3962 21.4387 17.2601 21.9554 16.0593 22ZM8.06417 3.53846C7.27075 3.54132 6.50949 3.85593 5.94139 4.41577C5.3733 4.97563 5.04287 5.73684 5.01996 6.53846V17.2954C5.00998 17.701 5.07916 18.1046 5.22355 18.4833C5.36793 18.8618 5.58468 19.208 5.86144 19.5019C6.13819 19.7959 6.46952 20.0318 6.8365 20.1963C7.20349 20.3607 7.59894 20.4506 8.00027 20.4605H16.0258C16.8273 20.4241 17.5833 20.0737 18.1337 19.4838C18.6842 18.8937 18.9857 18.1104 18.9746 17.2995V8.92308C18.9756 8.5788 18.8439 8.24766 18.6073 8L14.7604 3.94872C14.6374 3.81915 14.4899 3.71602 14.3265 3.64553C14.163 3.57503 13.9873 3.53862 13.8096 3.53846H8.06114H8.06417Z"
        fill="black"
      />
      <path
        d="M16.8949 9.19538C16.0061 9.19131 15.155 8.83188 14.5276 8.19558C13.9001 7.55928 13.5474 6.69786 13.5463 5.79949V2.83333C13.5463 2.62932 13.6265 2.43366 13.7692 2.28941C13.912 2.14514 14.1055 2.0641 14.3073 2.0641C14.5091 2.0641 14.7028 2.14514 14.8455 2.28941C14.9882 2.43366 15.0684 2.62932 15.0684 2.83333V5.79949C15.0687 6.29096 15.2616 6.76232 15.6048 7.11042C15.948 7.45852 16.4137 7.65502 16.9 7.65692H19.6773C19.8791 7.65692 20.0727 7.73797 20.2155 7.88223C20.3582 8.02648 20.4384 8.22213 20.4384 8.42615C20.4384 8.63016 20.3582 8.82583 20.2155 8.97008C20.0727 9.11434 19.8791 9.19538 19.6773 9.19538H16.8949Z"
        fill="black"
      />
    </svg>
  );
};
export { DownloadIcon };
