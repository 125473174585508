import {
  toggleFavoriteLocalStorage,
  getFavorites,
} from '@/Lib/helpers/favorites-local-storage';
import { BasicTrip, RecentDiscoveriesTrip, Trip } from '@/Lib/types/trip';
import React, { useEffect, useState } from 'react';
import { useLocation } from '@/Lib/hooks/useLocation';
import { CheckSessionCookie } from '@/Lib/helpers/auth0';
import { Login } from '../auth0/login';
import { useCookies } from 'react-cookie';
import { HeartSVG } from '@/Assets/icons/heart';
import { RudderAnalytics } from '../ruddarstak/rudderanalytics';
import {
  TProductAddedtoWishlist,
  TProductRemovedFromWishlist,
} from '@/DataTypes/track-events';
import { useAtom, useAtomValue } from 'jotai';
import {
  userAtom,
  userLoginStatusAtom,
  wishlistAtom,
} from '@/State/global/global.store';
import { TSimpleUser, TUser } from '@/DataTypes/user';
import { useCurrency } from '@/Lib/hooks/useCurrency';
import { InfoModal } from '../FPDC/info-modal';
import { getUserInfoFPDC, setUserInfoFPDC } from '@/Lib/helpers/fpdc';
import { CTADownload } from '../page-product/cta-download/cta-download';

type FavoriteProps = {
  type: string;
  trip: BasicTrip | Trip | RecentDiscoveriesTrip;
  deposit: number;
  price: number;
  revenue: number;
  originalPrice: number;
  originalDeposite: number;
  isShowVideo?: boolean;
};

declare const rudderanalytics: any;

const Favorite = ({
  type,
  trip,
  deposit,
  price,
  revenue,
  originalPrice,
  originalDeposite,
  isShowVideo = false,
}: FavoriteProps) => {
  const isUserLoggedIn = useAtomValue(userLoginStatusAtom);
  const [cookies] = useCookies(['auth']);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [liked, setLiked] = useState(false);
  const { currency } = useCurrency();
  const [wishlistTrigger, setWishlistTrigger] = useAtom(wishlistAtom);
  const [loading, setLoading] = useState(false);
  const user = useAtomValue(userAtom);

  const { location } = useLocation();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const rudderObj: TProductAddedtoWishlist | TProductRemovedFromWishlist = {
    currency: currency,
    content_type: 'product',
    product_name: trip.tripName,
    division: trip.division,
    destination: trip.destination,
    product_id: trip.id,
    value: price,
    deposit: deposit ? deposit : 0,
    price: price,
    original_price: originalPrice,
    original_deposit: originalDeposite,
    revenue: price,
    promotions: trip.promotions
      ? trip.promotions.map((promo) => {
          return {
            amount: promo.amount ? promo.amount[currency] : 0,
            code: promo.code,
            type: promo.type,
          };
        })
      : [],
    country: location.country.name,
    first_name: user?.firstName || null,
    email: user?.email || null,
  };

  const trackFavoriteEvent = (like: boolean, user?: TSimpleUser) => {
    let data = rudderObj;

    if (user) {
      data = {
        ...data,
        first_name: user.name || null,
        email: user.email || null,
      };
    }

    if (like) {
      RudderAnalytics.productAddedToWishlist(data);
    } else {
      RudderAnalytics.productRemovedFromWishlist(data);
    }
  };

  const handleLiked = (tripId: number) => {
    const favorites = getFavorites();
    if (favorites.findIndex((item) => item === tripId) > -1) {
      setLiked(true);
    } else {
      setLiked(false);
    }
  };

  const toggleLike = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const getUserInfo = getUserInfoFPDC();
    if (getUserInfo) {
      ToggleFavorite(getUserInfo);
    } else {
      setIsModalOpen(true);
    }
  };

  const ToggleFavorite = (user: TSimpleUser) => {
    setIsModalOpen(false);
    toggleFavoriteLocalStorage(trip.id);
    setUserInfoFPDC(user);
    trackFavoriteEvent(!liked, user);
    setLiked(!liked);
    setWishlistTrigger((p) => p + 1);
  };

  useEffect(() => {
    handleLiked(trip.id);
  }, [trip.id, wishlistTrigger]);

  return (
    <>
      <InfoModal
        isOpenModal={isModalOpen}
        setIsOpenModal={setIsModalOpen}
        afterLogin={ToggleFavorite}
        type="wishlist-init-form"
      />
      {loading ? (
        <>
          {' '}
          <div
            className={
              'hidden z-20  absolute  top-5 bg-red-900 right-4.375rem ' +
              (type != 'product' ? '' : 'md:block')
            }
          >
            <div className="button-load">
              <span className="load-circle-favorite"></span>
            </div>
          </div>
          <div
            className={
              'absolute w-7 h-7 ' +
              (type != 'product' ? '' : 'md:hidden ') +
              (type == 'smallcard' ? ' right-0.5 top-0.5' : ' right-3 top-3')
            }
          >
            <div className="button-load">
              <span className="load-circle-favorite-mobile"></span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={
              'hidden z-20  absolute top-5  gap-x-2 ' +
              (type != 'product' ? '' : 'md:flex flex-row-reverse right-5')
            }
          >
            <div
              className={
                ' group bg-light-600 w-10 h-10 rounded-full shadow-btn cursor-pointer overflow-hidden transition-width duration-300 transform  ' +
                (liked ? 'hover:w-48 ' : 'hover:w-36 ')
              }
              onClick={toggleLike}
            >
              <div className="flex w-10 h-10 justify-center items-center float-left ">
                <HeartSVG
                  className={
                    'w-7 h-7 ' +
                    (liked
                      ? 'animate-beat fill-current text-redPink '
                      : 'stroke-current text-dark-800 ')
                  }
                />
              </div>

              <div className="flex whitespace-nowrap h-10 items-center pr-2 text-sm text-dark-800  opacity-0 transition-all duration-300  group-hover:opacity-100">
                <span className="font-semibold">
                  {liked ? 'Remove from wishlist' : 'Add to wishlist'}
                </span>
              </div>
            </div>
            <CTADownload />
          </div>
          <div
            className={
              'z-20 w-7 h-7 absolute cursor-pointer ' +
              (type != 'product' ? '' : 'md:hidden ') +
              (type == 'smallcard' ? ' right-0.5 top-0.5' : ' right-3 top-3')
            }
            onClick={toggleLike}
          >
            <HeartSVG
              className={
                'w-7 h-7 ' +
                (liked
                  ? 'animate-beat stroke-light stroke-0.094rem  fill-current text-redPink '
                  : 'stroke-light stroke-0.094rem fill-current text-blackTrans66')
              }
            />
          </div>
        </>
      )}
    </>
  );
};
export default Favorite;
